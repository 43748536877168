import { createSetting } from '@shopex/finder'
import { formatTimeStampToStr } from '@/utils/index'

export const StatusOption = [
  {
    label: '全部',
    value: '0'
  },
  {
    label: '已绑定未使用',
    value: '1'
  },
  {
    label: '已使用',
    value: '2'
  },
  {
    label: '退货取消',
    value: '3'
  },
  {
    label: '已过期',
    value: '4'
  }
]

export const SOURCE_ENUM = {
  'jd': '京东到家',
  'tb': '淘宝',
  'mt': '美团闪购',
  'wxapp': '微信小程序',
  'dy': '抖音',
  'jdzy': '京东自营',
  'eleme': '饿了么',
  'amap': '高德',
  'qyg': '企业购小程序',
  'pos': '线下单'
}

const EnumToOptions = obj => {
  return Object.keys(obj).map(item => {
    return {
      label: obj[item],
      value: item
    }
  })
}

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '设备名称',
      key: 'device_name',
    },
    {
      name: '设备SN',
      key: 'device_sn',
    },
    {
      name: '订单编号',
      key: 'order_id',
    },
    {
      name: '会员id',
      key: 'user_id',
    },
    {
      name: '订单归属平台',
      key: 'order_source',
      type: 'select',
      options: EnumToOptions(SOURCE_ENUM)
    },
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
  ],
  columns: [
    { name: '保修服务编号', key: 'service_bn', minWidth: 120 },
    { name: '绑定设备SN', key: 'device_sn', minWidth: 120 },
    { name: '绑定设备', key: 'device_name', minWidth: 120 },
    { name: '保修名称', key: 'guarantee_name', minWidth: 100 },
    { name: '绑定订单', key: 'order_id', minWidth: 120 },
    {
      name: '订单来源',
      key: 'order_source',
      minWidth: 100,
      formatter: (v) => SOURCE_ENUM[v]
    },
    {
      name: '状态',
      key: 'status',
      minWidth: 100,
      formatter: (v) => StatusOption.find(item => item.value === v)?.label
    },
    { name: '会员ID', key: 'user_id', minWidth: 100 },
    {
      name: '绑定时间',
      key: 'created',
      minWidth: 150,
      render(_, {row}) {
        if(!row.created) return '-'
        return formatTimeStampToStr(row.created)
      }
    }
  ],
  actions: [
    {
      name: '导出',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
  ]
})
